<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th
          ><vs-checkbox
            v-model="selectAll"
            @input="handleSelectAll"
          />ALL</vs-th
        >
        <vs-th>Action</vs-th>
        <vs-th sort-key="code">Territory</vs-th>
        <vs-th sort-key="name">Valid From</vs-th>
        <vs-th sort-key="name">Valid To</vs-th>
        <vs-th sort-key="name">Min. Order Qty (SO)</vs-th>
        <vs-th sort-key="name">Handling Unit (SO)</vs-th>
        <!-- <vs-th sort-key="name">Type</vs-th> -->
        <vs-th sort-key="name">Create On</vs-th>
        <vs-th sort-key="name">Create By</vs-th>
        <vs-th sort-key="name">Updated On</vs-th>
        <vs-th sort-key="name">Updated By</vs-th>
        <vs-th sort-key="name">Status</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-checkbox
                v-model="exportID"
                color="success"
                :vs-value="data[indextr].id"
                @input="$emit('selected-items', exportID)"
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                v-if="this.$store.getters['user/hasPermission']('edit')"
                @click.stop="handleEdit(data[indextr].id)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
              />
              <!-- <vs-button
                @click.stop="handleDelete(data[indextr].id)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                title="Delete"
              /> -->
            </div>
          </vs-td>
          <vs-td :data="data[indextr].name_territory">
            {{ data[indextr].name_territory }}
          </vs-td>
          <vs-td :data="data[indextr].valid_from">{{
            data[indextr].valid_from | formatDateValidFromTO
          }}</vs-td>
          <vs-td :data="data[indextr].valid_to">{{
            data[indextr].valid_to | formatDateValidFromTO
          }}</vs-td>
          <vs-td :data="data[indextr].qty_total">{{
            data[indextr].qty_total
          }}</vs-td>
          <vs-td :data="data[indextr].name_unit">{{
            data[indextr].name_unit
          }}</vs-td>
          <!-- <vs-td :data="data[indextr].type">
            {{
              data[indextr].type === 2
                ? "ZDLS"
                : data[indextr].type === 1
                ? "ZDBB"
                : ""
            }}
          </vs-td> -->
          <vs-td :data="data[indextr].created_at">{{
            data[indextr].created_at | formatDate
          }}</vs-td>
          <vs-td :data="data[indextr].user_name_created">{{
            data[indextr].user_name_created
          }}</vs-td>
          <vs-td :data="data[indextr].updated_at">{{
            data[indextr].updated_at | formatDate
          }}</vs-td>
          <vs-td :data="data[indextr].user_name_updated">{{
            data[indextr].user_name_updated
          }}</vs-td>
          <vs-td :data="data[indextr].status">{{ data[indextr].status }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    territoryID: {
      type: Number,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      itemDel: 0,
      exportID: [],
      showingData: true,
      selectAll: false,
      IDItem: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleCreate() {
      this.customerId = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.territoryID ? this.territoryID : "",
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            for (let index = 0; index < resp.data.records.length; index++) {
              this.IDItem.push(resp.data.records[index].id);
            }
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      console.log("aaaaaaaaa", id);
      this.$router.push({
        name: "product-back-to-back-edit",
        params: { id: id },
      });
    },
    handleSelectAll(id) {
      console.log("yyy", id);
      console.log("yyy", this.exportID);
      if (id == false) {
        this.exportID = [];
      } else {
        this.exportID = this.IDItem;
      }
      this.$emit("selected-items", this.IDItem);
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(value)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    formatDateValidFromTO(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  mounted() {
    this.getData();
    this.$store.dispatch("user/getPermissions", "product-back-to-back");
  },
};
</script>
